import Lists from "./Lists";

function CuratedLists(): JSX.Element {
  return (
    <div className="bg-primary-50 px-4 py-20">
      <div className="mx-auto flex max-w-3xl flex-col justify-center md:gap-x-40 lg:flex-row">
        <div className="flex flex-col justify-center md:mb-0 xl:max-w-3xl">
          <div className="mb-8 text-center text-[43px] font-black text-secondary-400 expertCuratedConflict:text-5xl lg:text-6xl">
            <div className="inline-flex flex-col flex-wrap items-center justify-center">
              Explore our
              <div>
                <span className="relative z-0">
                  expert-curated
                  <svg
                    className="absolute -bottom-1 left-0 -z-10 mb-1 hidden lg:block"
                    fill="none"
                    height="8"
                    viewBox="0 0 427 8"
                    width="427"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.23309 4.68592C17.5226 3.88266 75.0305 2.02536 122.152 0.973403C180.467 -0.3276 368.805 0.387695 399.964 2.02942C438.868 4.07853 434.769 5.59647 388.316 6.34244C309.913 7.60267 -0.00197183 7.65927 0.000863582 6.41372C0.00066906 5.77465 3.25678 4.99698 7.23309 4.68592Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                  <svg
                    className="absolute bottom-0 left-0 -z-10 mb-1 hidden expertCuratedConflict:block lg:hidden"
                    fill="none"
                    height="6"
                    viewBox="0 0 341 6"
                    width="341"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.78388 3.70282C14.0124 3.017 60.0007 1.44003 97.6834 0.550646C144.317 -0.549285 294.927 0.112961 319.844 1.52828C350.953 3.29481 347.676 4.59483 310.528 5.22212C247.83 6.28186 -0.0025706 6.24952 0 5.18191C0 4.63413 2.60403 3.96841 5.78388 3.70282Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                  <svg
                    className="absolute bottom-1 left-0 -z-10 hidden 3xs:block expertCuratedConflict:hidden"
                    fill="none"
                    height="6"
                    viewBox="0 0 307 6"
                    width="307"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.207 3.70282C12.6186 3.017 54.0072 1.44003 87.8728 0.550646C129.651 -0.549285 265.383 0.112961 287.826 1.52828C316.410 3.29481 313.503 4.59483 279.329 5.22212C223.091 6.28186 -0.002314 6.24952 0 5.18191C0 4.63413 2.434 3.96841 5.207 3.70282Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                  <svg
                    className="absolute -bottom-1 left-0 -z-10 mb-1 block 3xs:hidden"
                    fill="none"
                    height="6"
                    viewBox="0 0 157 6"
                    width="157"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.665 3.70282C6.442 3.017 27.569 1.44003 44.907 0.550646C66.408 -0.549285 135.869 0.112961 147.219 1.52828C161.812 3.29481 159.682 4.59483 142.502 5.22212C113.981 6.28186 -0.001393 6.24952 0 5.18191C0 4.63413 1.122 3.96841 2.665 3.70282Z"
                      fill="#3EC0DD"
                    />
                  </svg>
                </span>
                <p>job lists.</p>
              </div>
            </div>
          </div>
          <h2 className="mx-auto mb-12 max-w-3xl text-center text-xl text-secondary-400">
            Our team handpicks the most exciting opportunities into lists for you to discover – updated daily.
          </h2>
        </div>
      </div>
      <Lists />
    </div>
  );
}

export default CuratedLists;
