import { useEffect, useState } from "react";

type Props =
  | {
      debounce?: number;
    }
  | undefined;

type Api = {
  width: number;
  height: number;
};

function usePageDimensions({ debounce }: Props = {}): Api {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const update = (): void => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  let timerId: ReturnType<typeof setTimeout>;

  const updateSize = (): void => {
    if (debounce !== undefined && debounce >= 0) {
      clearTimeout(timerId);
      timerId = setTimeout(update, debounce);
    } else {
      update();
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { width, height };
}

export default usePageDimensions;
