/* eslint-disable react/no-array-index-key */
import { useState } from "react";

import { FaMinus, FaPlus } from "react-icons/fa6";

import { FAQ } from "components/SEO/FAQ/faq";
import FAQSchema from "components/SEO/FAQ/FAQSchema";

function QuestionItem({
  question,
  answer,
  isOpen,
  setIsOpen,
}: {
  question: string;
  answer: string;
  isOpen: boolean;
  setIsOpen: () => void;
}): JSX.Element {
  return (
    <div className="pt-8 sm:px-8">
      <button
        className={`flex w-full items-center justify-between gap-4 text-xl ${isOpen ? "text-primary-500" : "text-secondary-400"}`}
        type="button"
        onClick={setIsOpen}
      >
        <div className="flex items-center gap-4">
          <div className="flex size-10 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-2 text-base text-secondary-400">
            💬
          </div>
          <h3 className="text-left">{question}</h3>
        </div>
        <div className="text-base text-secondary-400">
          {isOpen ? <FaMinus className="text-primary-500" /> : <FaPlus />}
        </div>
      </button>
      <p className={`overflow-hidden transition-all duration-100 ease-in-out ${isOpen ? "mt-4" : "max-h-0"}`}>
        {answer}
      </p>
    </div>
  );
}

function Questions({ faq }: { faq: FAQ[] }): JSX.Element {
  const [isOpen, setIsOpen] = useState(-1);

  return (
    <>
      <div className="container z-10 mx-auto flex max-w-5xl flex-col justify-between px-4 py-20 sm:px-8">
        <div className="flex flex-col justify-center md:gap-x-40 lg:flex-row">
          <div className="mx-auto flex max-w-3xl flex-col justify-center md:mb-0">
            <div className="mb-8 text-center text-5xl font-black text-secondary-400 lg:text-6xl">
              <div className="inline-flex flex-col flex-wrap items-center justify-center">Got questions?</div>
            </div>
            <h2 className="mx-auto mb-12 max-w-3xl text-center text-xl text-secondary-400">
              Explore our FAQ section to learn more.
            </h2>
          </div>
        </div>
        <div className="flex flex-col gap-8 divide-y divide-gray-100">
          {faq.map((item, index) => (
            <QuestionItem
              key={index}
              answer={item.answer}
              isOpen={isOpen === index}
              question={item.question}
              setIsOpen={() => (index === isOpen ? setIsOpen(-1) : setIsOpen(index))}
            />
          ))}
        </div>
      </div>
      <FAQSchema faq={faq} />
    </>
  );
}

export default Questions;
