export interface FAQ {
  question: string;
  answer: string;
}

export const landingFAQ: FAQ[] = [
  {
    question: "Is Simplify free? How do you make money?",
    answer:
      "We dropped out of college and started Simplify to equip job seekers with the tools they need to put their best foot forward in the job search. The base version of Simplify and Simplify Copilot – including unlimited job tracking and autofill – is and will be free forever. We make money by charging companies to post jobs and promote their openings – we don't sell your data. We also have a premium subscription called Simplify+ that offers an additional suite of AI features to help you supercharge your job search.",
  },
  {
    question: "How does Simplify work?",
    answer:
      "Think of Simplify like a personal Hollywood-agent for your career. When you create a profile, you'll tell us the skills you have, the types jobs youre looking for, and any dealbreakers (minimum salary, location, etc.). Our AI will match you with specific opportunities that fit you from the millions of opportunities in our database. When you apply, we'll help you autofill your application and show you the most important keywords that are missing from your resume. We have an AI resume builder that lets you tailor your resume in seconds. Finally, our tracker helps you stay organized across all the jobs you've applied to. Ready to see Simplify in action? Click the teal “Sign-Up” button to get started!",
  },
  {
    question: "How does Simplify handle my data?",
    answer:
      "We're internet users too and place a heavy emphasis and commitment to your privacy. First and foremost, we do not sell your data. The data you share with us is used to help match you with relevant jobs and autofill your applications. Simplify also operates as a hiring marketplace, meaning recruiters often use Simplify to post exclusive opportunities and find perfect-fit candidates (like you!). None of your data is shared with anyone off-platform without your consent – that's a promise!",
  },
  {
    question: "How does Simplify get the job postings it recommends me?",
    answer:
      "The majority of the job openings you'll find on Simplify are taken directly from each company's native job board. Our system regularly checks the career pages of the 20,000+ companies on Simplify for new openings to recommend you. Each of the companies on our platform are vetted to ensure that every job you see is legit and up to date. We also work with a number of employers who exclusively post their roles on Simplify – so you'll see opportunities here you won't find anywhere else on the web!",
  },
];

export const copilotFAQ: FAQ[] = [
  {
    question: "What job boards and ATS's does Simplify's job application autofill support?",
    answer:
      "Simplify Copilot is able to autofill job applications on over 100 job boards and application portals including Workday, Greenhouse, iCIMS, Taleo, Avature, Lever, and SmartRecruiters, just to name a few. Our extension also integrates with job boards like Linkedin and Indeed to analyze job descriptions and identify keywords missing in your resume. You'll know when a site is supported if the Simplify popup appears – we're constantly adding compatibility to new job boards!",
  },
  {
    question: "Is there a limit to how many applications I can autofill with Simplify Copilot?",
    answer:
      "There is no limit on how many applications you can submit with Copilot! Our autofill, application tracker, job matches, and basic resume builder are features we commit to keeping free for everyone.",
  },
  {
    question:
      "Does Simplify's autofill application extension work for both full-time jobs and internship applications?",
    answer:
      "Yes! The Simplify extension will autofill your application on any supported job platform – regardless of the nature or type of the job. We've helped users apply to jobs ranging from nurses to truck drivers to software engineers and even to CEO's!",
  },
  {
    question: "How does Simplify autofill my job applications?",
    answer:
      "Think of Simplify like Google's generic autofill but designed specifically to help you accurately fill out job applications. When you first sign up on Simplify, we'll ask you to set up a profile with the data we'll need to autofill your job applications. After that, the only thing you'll need is to add the Simplify Copilot extension to your web browser. Now, when you navigate to any compatible job posting, you'll see the Simplify Copilot popup and give you the option to autofill the application. Simplify analyzes the application questions and maps them to the data you provided on your profile. For questions specific to the job, you can use our AI to generate a personalized response based on your resume and work history!",
  },
];

export const builderFAQ: FAQ[] = [
  {
    question: "What exactly is a professional resume and what is its purpose?",
    answer:
      "A professional resume is a summary of your work history, skills, and career achievements that showcases why your background makes you a good fit for the job you're applying for. This means that your resume should be changing depending on the specific job requirements and responsibilities. It's crucial that the professional resume you create is tailored to the job requirements and integrates well with the company's ATS or hiring software – your goal is to catch the attention of hiring managers and stand out from other candidates!",
  },
  {
    question: "What is an AI resume builder?",
    answer:
      "An AI resume builder is simply a tool to help you quickly create a professional resume that leverages AI to help reword and reorganize various aspects of your career history. Most resume builders (including Simplify's!) come with pre-designed templates that are compatible with the hiring softwares and ATS's used by employers to review resumes, and allow you to easily edit the sections of your resume in a few clicks. You'll be able to use AI to quickly generate professional summaries, reword bullet points, and more!",
  },
  {
    question: "How does Simplify's AI generate my resume bullets, professional summary, and cover letter?",
    answer:
      "When you sign up on Simplify, you'll be asked to upload an existing resume or share some short details about your career history and skills. Our AI will analyze this data and compare it with the job description you're applying for to output information that highlights your strengths and why you would be a good fit for that specific opportunity. We trained our AI (which is powered by OpenAI's GPT) on recruiter-approved resumes and cover letters to ensure that the information we generate fit what hiring managers are looking for and help you put your best foot forward.",
  },
  {
    question: "Can you use ChatGPT to create a resume? What are the advantages to using Simplify?",
    answer:
      "More than 50% of job seekers are using AI in their job search – however, using ChatGPT to create your resume is NOT recommended. ChatGPT is a general-purpose LLM that is not designed to help you craft detailed resumes that are tailored to the job you are applying for. Simplify's AI resume builder and cover letter generator is powered by the same foundational AI-model used by ChatGPT but has been trained on specific recruiter-vetted resumes and cover letters. Our AI was designed specifically to help you tailor professional documents and ensures that everything you create follows best practices – a huge step up from using ChatGPT or Claude.",
  },
];

export const trackerFAQ: FAQ[] = [
  {
    question: "What is a job tracker? Can't I just use a spreadsheet?",
    answer:
      "A job tracker is simply a tool used by job seekers to organize and manage job applications and interviews during an ongoing job search. This can be something as basic as a spreadsheet or something as feature-rich as Simplify's free job tracker. The goal of a good job tracker is to help you keep track of upcoming interviews, referrals, notes, and remind you to follow-up with recruiters. A good job tracker can vastly improve the efficiency of your job search and help you get hired faster!",
  },
  {
    question: "How much does the job tracker cost?",
    answer:
      "Simplify's job tracker is free – and we plan to keep it that way! We started Simplify with a mission to give job seekers the tools they need to succeed in their job search. Our job tracker is designed to help you stay organized throughout your search and stay on top of upcoming interviews/deadlines. Our autofill extension, application tracker, and job matches are all free features we commit to keeping free – forever.",
  },
  {
    question: "What's the best way to track job applications? How should I be using Simplify?",
    answer:
      "Our recruiters recommend bookmarking jobs you're excited about and crafting tailored resumes and cover letters before you apply. You can save these documents within Simplify's job tracker and record notes from your interviews. When you move forward in the recruiting process for a given job, you can easily update your status which helps you visualize where you are in your search. Before any interview, you'll be able to see all the notes, referrals, and documents you used in the process and increase your chances of landing that offer!",
  },
];
