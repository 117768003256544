// eslint-disable-next-line import/prefer-default-export
export const companies = [
  { title: "Space X", logo: "/images/landing/logos/spacex.png" },
  { title: "Discord", logo: "/images/landing/logos/discord.png" },
  {
    title: "Notion",
    logo: "/images/landing/logos/notion.png",
  },
  { title: "Canva", logo: "/images/landing/logos/canva.png" },
  { title: "Duolingo", logo: "/images/landing/logos/duolingo.png" },
  { title: "Netflix", logo: "/images/landing/logos/netflix.png" },
  { title: "Instacart", logo: "/images/landing/logos/instacart.png" },
  { title: "Visa", logo: "/images/landing/logos/visa.png" },
  { title: "Capital one", logo: "/images/landing/logos/capital.png" },
];
