/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useState } from "react";

import Image from "next/image";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { testimonials } from "components/Landing/Testimonials/data";
import usePageDimensions from "utils/hooks/usePageDimensions";

import "./styles.scss";

interface TestimonialsProps {
  bgColor?: string;
}

function Testimonials({ bgColor = "bg-primary-50" }: TestimonialsProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(2);
  const { width } = usePageDimensions();

  let centerSlidePercentage = 70;

  if (width >= 640 && width < 1024) {
    centerSlidePercentage = 50;
  } else if (width >= 1024 && width < 1500) {
    centerSlidePercentage = 33.33;
  } else if (width >= 1500) {
    centerSlidePercentage = 25;
  }

  return (
    <div className={`overflow-hidden ${bgColor} px-4 py-20`}>
      <h2 className="mx-auto max-w-lg text-center text-xl text-secondary-300">
        Join over <b>{process.env.NEXT_PUBLIC_USER_COUNT}</b> candidates that hear back <b>25% more</b> with Simplify
        than on other platforms 🎉
      </h2>
      <div className="mx-auto flex max-w-screen-2xl items-center justify-center gap-4">
        <div className="mt-8 flex w-full items-center justify-center gap-2">
          <Carousel
            autoPlay
            centerMode
            infiniteLoop
            preventMovementUntilSwipeScrollTolerance
            swipeable
            useKeyboardArrows
            axis="horizontal"
            centerSlidePercentage={centerSlidePercentage}
            className="mx-auto flex w-full items-center gap-4"
            interval={4000}
            renderArrowNext={() => (
              <div
                className={`absolute right-0 z-20 hidden h-full w-[400px] items-center justify-end bg-gradient-to-r from-transparent via-transparent md:flex ${bgColor === "bg-primary-50" ? "to-primary-50" : "to-white"}`}
              >
                <button
                  aria-label="Prev"
                  className="mt-8 flex items-center justify-center rounded-full border border-gray-100 bg-white p-3 sm:relative"
                  type="button"
                  onClick={() => setSelectedItem(selectedItem + 1)}
                >
                  <BsChevronRight className="size-6 text-primary-400" />
                </button>
              </div>
            )}
            renderArrowPrev={() => (
              <div
                className={`absolute left-0 z-20 hidden h-full w-[400px] items-center justify-start bg-gradient-to-l from-transparent via-transparent to-primary-50 md:flex ${bgColor === "bg-primary-50" ? "to-primary-50" : "to-white"}`}
              >
                <button
                  aria-label="Next"
                  className="mt-8 flex items-center justify-center rounded-full border border-gray-100 bg-white p-3 sm:relative"
                  type="button"
                  onClick={() => setSelectedItem(selectedItem - 1)}
                >
                  <BsChevronLeft className="size-6 text-primary-400" />
                </button>
              </div>
            )}
            selectedItem={selectedItem}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeScrollTolerance={50}
            transitionTime={600}
            onChange={(index): void => setSelectedItem(index)}
          >
            {[...testimonials, ...testimonials, ...testimonials, ...testimonials].map((item, index) => (
              <div key={index} className="px-4 pb-2 pt-9">
                <div className="mx-auto flex h-84 w-full max-w-md flex-col rounded-2xl border border-gray-100 bg-white p-6 text-secondary-400 shadow-md sm:h-60">
                  <div className="absolute top-0 max-w-20">
                    <Image alt={item.title} className="z-10 rounded-full" height={80} src={item.image} width={80} />
                  </div>
                  <div className="flex justify-end">
                    <svg fill="none" height="28" viewBox="0 0 31 28" width="31" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.7323 27.5813C27.2556 21.2619 30.3134 14.5348 30.3134 8.52116C30.3134 3.62871 26.644 0.0613003 22.9747 0.0613003C19.7131 0.0613003 17.063 2.71138 17.063 5.973C17.063 10.152 20.1208 12.2924 24.6055 12.2924C24.6055 17.2868 23.0766 20.2426 18.2861 25.1351L20.7323 27.5813ZM3.71069 27.5813C10.2339 21.2619 13.2917 14.5348 13.2917 8.52116C13.2917 3.62871 9.62239 0.0613003 5.95305 0.0613003C2.69143 0.0613003 0.0413513 2.71138 0.0413513 5.973C0.0413513 10.152 3.09913 12.2924 7.58387 12.2924C7.58387 17.2868 6.05498 20.2426 1.26446 25.1351L3.71069 27.5813Z"
                        fill="#3EC0DD"
                        opacity="0.5"
                      />
                    </svg>
                  </div>
                  <div className="mt-2 flex flex-auto flex-col justify-between gap-7">
                    <h1 className="text-left text-base leading-6">&quot;{item.quote}&quot;</h1>
                    <div className="flex flex-col items-start justify-start gap-1 sm:flex-row sm:items-center sm:justify-between">
                      <div>
                        <p className="text-left text-sm font-bold">{item.title}</p>
                        <p className="text-left text-sm text-gray-400">{item.name}</p>
                      </div>
                      <div className="max-w-28 sm:max-w-24">
                        <Image alt={item.name} className="object-contain" height={36} src={item.logo} width={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
