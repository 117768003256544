/* eslint-disable react/no-danger */
import Head from "next/head";

const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Simplify",
  alternateName: "SimplifyJobs",
  url: "https://simplify.jobs",
  logo: "https://simplify.jobs/images/logos/logo.svg",
  image: "https://simplify.jobs/images/logos/logo.svg",
  email: "support@simplify.jobs",
  description:
    "Your entire job search powered by one profile. Get personalized job recommendations, craft tailored resumes, autofill and track your job applications. We're here for every step of the process.",
  sameAs: [
    "https://twitter.com/joinsimplify",
    "https://www.linkedin.com/company/simplifyjobs/",
    "https://www.facebook.com/joinsimplify",
    "https://www.instagram.com/simplify.jobs/",
    "https://www.tiktok.com/@joinsimplify",
  ],
};

export default function OrganizationSchema(): JSX.Element {
  function addOrganization(): { __html: string } {
    return {
      __html: JSON.stringify(organization),
    };
  }

  return (
    <Head>
      <script dangerouslySetInnerHTML={addOrganization()} key="organization-jsonld" type="application/ld+json" />
    </Head>
  );
}
