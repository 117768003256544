/* eslint-disable react/no-danger */
import Head from "next/head";

interface Props {
  faq: { question: string; answer: string }[];
}

export default function FAQSchema({ faq }: Props): JSX.Element {
  function addFAQJsonLd(): { __html: string } {
    function generateFAQ(): string {
      return faq
        .map(
          ({ question, answer }) => `{
            "@type": "Question",
            "name": ${JSON.stringify(question)},
            "acceptedAnswer": {
              "@type": "Answer",
              "text": ${JSON.stringify(answer)}
            }
          },
        `
        )
        .join("")
        .replace(/,\s*$/, ""); // removes trailing comma
    }

    return {
      __html: JSON.stringify(
        JSON.parse(`{"@context": "https://schema.org/","@type": "FAQPage","mainEntity": [${generateFAQ()}]}`)
      ),
    };
  }

  return (
    <Head>
      <script dangerouslySetInnerHTML={addFAQJsonLd()} key="faq-jsonld" type="application/ld+json" />
    </Head>
  );
}
