import Link from "next/link";

import { items, itemsSecondRow, social } from "components/Landing/Footer/FooterNav/data";
import ImageLink from "components/Landing/Footer/ImageLink";
import Logo from "components/Landing/Logo";
import OrganizationSchema from "components/SEO/Organization";

export default function Footer(): JSX.Element {
  const renderNavItems = (
    list: {
      label: string;
      url: string;
    }[]
  ): JSX.Element[] =>
    list.map((item, index, { length }) => (
      <li key={item.label} className={`whitespace-nowrap text-white ${length - 1 === index ? "" : "lg:mr-8"} lg:mb-0`}>
        <Link href={item.url}>{item.label}</Link>
      </li>
    ));

  const renderSocial = (): JSX.Element[] =>
    social.map((item) => (
      <li key={item.image.alt} className="mx-2">
        <ImageLink item={item} />
      </li>
    ));

  return (
    <section className="bg-primary-400 p-8 lg:py-24">
      <div className="mx-auto flex max-w-screen-2xl flex-col items-center">
        <div className="flex w-full flex-col items-center justify-around gap-10 lg:flex-row">
          <div>
            <Logo inverted />
            <ul className="mt-3 flex list-none justify-center lg:hidden">{renderSocial()}</ul>
          </div>
          <div className="flex list-none flex-col">
            <ul className="flex flex-col items-center justify-center gap-6 lg:flex-row">{renderNavItems(items)}</ul>
            <ul className="mt-6 flex flex-col items-center justify-center gap-6 lg:mt-3 lg:flex-row">
              {renderNavItems(itemsSecondRow)}
            </ul>
          </div>
          <ul className="hidden list-none lg:flex">{renderSocial()}</ul>
        </div>
        <p className="mt-12 text-center text-white">
          &copy;&nbsp;&nbsp;{`${new Date().getFullYear()} Simplify. All rights reserved`}
        </p>
      </div>
      <OrganizationSchema />
    </section>
  );
}
