import { FaStarHalfAlt } from "react-icons/fa";
import { FaRegStar, FaStar } from "react-icons/fa6";

function StarRating({ rating }: { rating: number }): JSX.Element {
  const stars = [];

  const roundedRating = Math.round(rating * 2) / 2;

  for (let i = 1; i <= 5; i += 1) {
    if (roundedRating >= i) {
      stars.push(<FaStar key={i} className="text-warning-400" />);
    } else if (roundedRating + 0.5 === i) {
      stars.push(<FaStarHalfAlt key={i} className="text-warning-400" />);
    } else {
      stars.push(<FaRegStar key={i} className="text-warning-400" />);
    }
  }

  return (
    <div className="flex gap-0.5">
      {stars.map((star, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>{star}</span>
      ))}
    </div>
  );
}

export default StarRating;
