export const testimonials = [
  {
    image: "/images/landing/reviews/cam.jpeg",
    name: "Cameron joined Goldman",
    quote: "Simplify was crucial in helping me keep track of my applications and stay organized while interviewing.",
    title: "Banking Analyst",
    industry: "Investment Banking",
    textColorIcon: "text-white",
    colorIcon: "bg-green-500",
    logo: "/images/landing/reviews/goldman.png",
  },
  {
    image: "/images/landing/reviews/winston.jpeg",
    name: "Winston joined FYPM",
    quote: "I got matched with startups I wouldn't have found myself and signed an offer within a week of applying!",
    title: "Operations Intern",
    industry: "Operations & Strategy",
    textColorIcon: "text-white",
    colorIcon: "bg-slate-600",
    logo: "/images/landing/reviews/fypm.png",
  },
  {
    image: "/images/landing/reviews/eman.jpeg",
    name: "Emmanuel joined Polydelta",
    quote: "I found a ton of exciting roles at startups I'd never heard of with Simplify!",
    title: "Software Engineer",
    industry: "Software Engineering",
    textColorIcon: "text-primary-100",
    colorIcon: "bg-primary-400",
    logo: "/images/landing/reviews/polydelta.png",
  },
  {
    image: "/images/landing/reviews/grace.jpg",
    name: "Grace joined Google",
    quote: "Simplify notified me about Google's APM program the day it opened which was crucial in landing the offer!",
    title: "Associate Product Manager",
    industry: "Product",
    textColorIcon: "text-white",
    colorIcon: "bg-violet-400",
    logo: "/images/landing/reviews/google.png",
  },
  {
    image: "/images/landing/reviews/harshit.jpeg",
    name: "Harshit joined Meta",
    quote: "Simplify made it easy to find senior positions I that fit my requirements and qualifications!",
    title: "Principal TPM",
    industry: "Senior Management",
    textColorIcon: "text-white",
    colorIcon: "bg-yellow-500",
    logo: "/images/landing/reviews/meta.png",
  },
  {
    image: "/images/landing/reviews/albert.png",
    name: "Albert joined Jane Street",
    quote: "I love the curated matches on Simplify. Makes it super easy to find jobs in specific industries!",
    title: "Software Engineer Intern",
    industry: "Quant Finance",
    textColorIcon: "text-white",
    colorIcon: "bg-rose-400",
    logo: "/images/landing/reviews/janest.png",
  },
  {
    image: "/images/landing/reviews/divya.jpg",
    name: "Divya joined Insight",
    quote: "Simplify was definitely the most helpful platform I used during my job search.",
    title: "Summer Associate",
    industry: "Finance",
    textColorIcon: "text-white",
    colorIcon: "bg-orange-400",
    logo: "/images/landing/reviews/insight.png",
  },
  {
    image: "/images/landing/reviews/rio.jpeg",
    name: "Rio joined Deloitte",
    quote: "I've saved hours of time that I would've spent re-typing my resume on job applications.",
    title: "Business Analyst",
    industry: "Business",
    textColorIcon: "text-white",
    colorIcon: "bg-teal-500",
    logo: "/images/landing/reviews/deloitte.png",
  },
  {
    image: "/images/landing/reviews/jessica.jpeg",
    name: "Jessica joined Alation",
    quote: "I would have never known about my internship if not for Simplify!",
    title: "Marketing Intern",
    industry: "Marketing",
    textColorIcon: "text-white",
    colorIcon: "bg-pink-400",
    logo: "/images/landing/reviews/alation.png",
  },
];

export const mobileTestimonials = [
  {
    image: "/images/landing/reviews/jessica.jpeg",
    name: "Jessica joined Alation",
    quote: "I would have never known about my internship if not for Simplify!",
    title: "Marketing Intern @ Alation",
    industry: "Marketing",
    textColorIcon: "text-white",
    colorIcon: "bg-pink-400",
    logo: "/images/landing/reviews/alation.png",
  },
  {
    image: "/images/landing/reviews/harshit.jpeg",
    name: "Harshit joined Meta",
    quote: "Simplify made it easy to find senior positions that fit my requirements and qualifications!",
    title: "Principal TPM @ Meta",
    industry: "Senior Management",
    textColorIcon: "text-white",
    colorIcon: "bg-yellow-500",
    logo: "/images/landing/reviews/meta.png",
  },
  {
    image: "/images/landing/reviews/winston.jpeg",
    name: "Winston joined FYPM",
    quote: "I got matched with startups I wouldn't have found myself and signed an offer within a week of applying!",
    title: "Operations Intern @ FYPM",
    industry: "Operations & Strategy",
    textColorIcon: "text-white",
    colorIcon: "bg-slate-600",
    logo: "/images/landing/reviews/fypm.png",
  },
  {
    image: "/images/landing/reviews/cam.jpeg",
    name: "Cameron joined Goldman",
    quote: "Simplify was crucial in helping me keep track of my applications and stay organized while interviewing.",
    title: "Banking Analyst @ Goldman",
    industry: "Investment Banking",
    textColorIcon: "text-white",
    colorIcon: "bg-green-500",
    logo: "/images/landing/reviews/goldman.png",
  },
  {
    image: "/images/landing/reviews/grace.jpg",
    name: "Grace joined Google",
    quote: "Simplify notified me about Google's APM program the day it opened which was crucial in landing the offer!",
    title: "Product Manager @ Google",
    industry: "Product",
    textColorIcon: "text-white",
    colorIcon: "bg-violet-400",
    logo: "/images/landing/reviews/google.png",
  },
  {
    image: "/images/landing/reviews/albert.png",
    name: "Albert joined Jane Street",
    quote: "I love the curated matches on Simplify. Makes it super easy to find jobs in specific industries!",
    title: "SWE Intern @ Jane Street",
    industry: "Quant Finance",
    textColorIcon: "text-white",
    colorIcon: "bg-rose-400",
    logo: "/images/landing/reviews/janest.png",
  },
  {
    image: "/images/landing/reviews/divya.jpg",
    name: "Divya joined Insight",
    quote: "Simplify was definitely the most helpful platform I used during my job search.",
    title: "Investor @ Insight Partners",
    industry: "Finance",
    textColorIcon: "text-white",
    colorIcon: "bg-orange-400",
    logo: "/images/landing/reviews/insight.png",
  },
  {
    image: "/images/landing/reviews/rio.jpeg",
    name: "Rio joined Deloitte",
    quote: "I've saved hours of time that I would've spent re-typing my resume on job applications.",
    title: "Business Analyst @ Deloitte",
    industry: "Business",
    textColorIcon: "text-white",
    colorIcon: "bg-teal-500",
    logo: "/images/landing/reviews/deloitte.png",
  },
  {
    image: "/images/landing/reviews/eman.jpeg",
    name: "Emmanuel joined Polydelta",
    quote: "I found a ton of exciting roles at startups I'd never heard of with Simplify!",
    title: "Software Engineer @ Polydelta AI",
    industry: "Software Engineering",
    textColorIcon: "text-primary-100",
    colorIcon: "bg-primary-400",
    logo: "/images/landing/reviews/polydelta.png",
  },
];
