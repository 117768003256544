import { useEffect, useState } from "react";

import Image from "next/image";
import Link from "next/link";
import { BsChevronDown } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa6";

import { lists } from "components/Landing/Footer/data";
import usePageDimensions from "utils/hooks/usePageDimensions";

interface CardProps {
  item: { title: string; type: string; link: string; icon: string };
}

function Card({ item }: CardProps): JSX.Element {
  return (
    <Link
      className="group col-span-1 flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-4 transition-all duration-200 hover:border-primary-400 hover:shadow-lg focus:border-primary-400 focus:shadow-lg"
      href={item.link}
    >
      <div>
        <div className="flex items-start justify-between">
          <Image alt="icon" height={56} src={item.icon} width={56} />
          <div className="rounded-lg border border-gray-100 bg-gray-50 p-2 text-secondary-400 group-hover:border-primary-400 group-focus:border-primary-400">
            <FaArrowRight
              className="-rotate-45 transition-all duration-200 group-hover:rotate-0 group-hover:text-primary-400 group-focus:rotate-0 group-focus:text-primary-400"
              size={18}
            />
          </div>
        </div>
      </div>
      <h2 className="text-left text-lg font-medium text-secondary-400 group-hover:text-primary-400 group-focus:text-primary-400">
        {item.title}
      </h2>
    </Link>
  );
}

function Lists(): JSX.Element {
  const { width } = usePageDimensions();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (width >= 1024) setShowMore(true);
  }, [width]);

  return (
    <div className="z-10 mx-auto flex w-full flex-col justify-between">
      <div className="grid w-full max-w-7xl grid-cols-1 gap-5 md:grid-cols-2 lg:mx-auto lg:grid-cols-3">
        {(showMore ? lists : lists.slice(0, width < 768 ? 3 : 4)).map((item) => (
          <Card key={item.title} item={item} />
        ))}
      </div>
      <div className="mt-4 flex justify-center lg:hidden">
        <button
          className="flex items-center gap-2 text-gray-400"
          type="button"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {`Show ${showMore ? "less" : "more"} jobs lists`}{" "}
          <BsChevronDown className={`${showMore ? "-rotate-180" : "rotate-0"} size-4 text-gray-400`} />
        </button>
      </div>
    </div>
  );
}

export default Lists;
